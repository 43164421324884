import { Optimizations } from "@futurefashion/dam-api-client";
import axios from "axios";
import _ from "lodash";
import { fromPromise } from "xstate";

import apiClient from "@/blackbox/api-client";

import { Field, FieldType, RapidPipelineSchema } from "./types";

const getSchema = async () =>
  await axios
    .get("/files/rp-3d-processing-schema.json")
    .then((response) => response.data);

export const PROPERTIES_TO_SKEP = [
  "version",
  "import.USD",
  "import.materials",
  "3dEdit.meshNormals",
  "3dEdit.materialEdit.alphaBlendToMaskThreshold",
  "3dEdit.materialEdit.alphaMapToOpaqueThreshold",
  "meshCulling.occlusionCulling.quality",
  "meshCulling.occlusionCulling.ignoreTransparency",
  "meshCulling.occlusionCulling.runAfterDecimator",
  "meshCulling.occlusionCulling.diffusion",
  "meshCulling.smallFeatureCulling.runAfterDecimator",
  "optimize.animationOptimization",
  "optimize.animationOptimization",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveTopology",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveNormals",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveMeshBorders",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveMaterialBorders",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.collapseUnconnectedVertices",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.boundaryPreservationFactor",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.collapseDistanceThreshold",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.method",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.deviation",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.materialMerger",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.resolution",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.keepMaterialsUVs.dropTextures",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.keepMaterialsUVs.atlasGenerator2ndUV",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.keepMaterialsUVs.forceNormalRebaking",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialMerger.materialRegenerator",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialMerger.materialMergingMethod",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialMerger.keepTiledUVs",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialMerger.tilingThreshold",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialUVAggregator.dropTextures",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialUVAggregator.atlasGenerator2ndUV",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialUVAggregator.allowRectangularAtlases",
  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization.materialUVAggregator.forceNormalRebaking",
  "optimize.3dModelOptimizationMethod.onlyMaterial.keepMaterialsUVs.dropTextures",
  "optimize.3dModelOptimizationMethod.onlyMaterial.keepMaterialsUVs.atlasGenerator2ndUV",
  "optimize.3dModelOptimizationMethod.onlyMaterial.keepMaterialsUVs.forceNormalRebaking",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialMerger.materialRegenerator",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialMerger.materialMergingMethod",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialMerger.keepTiledUVs",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialMerger.tilingThreshold",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialUVAggregator.dropTextures",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialUVAggregator.atlasGenerator2ndUV",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialUVAggregator.allowRectangularAtlases",
  "optimize.3dModelOptimizationMethod.onlyMaterial.materialUVAggregator.forceNormalRebaking",
  "export.0.fileName",
  "export.0.preserveTextureFilenames",
  "export.0.textureMapFilePrefix",
  "export.0.reencodeTextures",
  "export.0.discard",
  "export.0.format.glb.pbrMaterial.textureCompression",
  "export.0.format.glb.pbrMaterial.separateOcclusionMap",
  "export.0.format.glb.pbrMaterial.excludePbrExtensions",
  "export.0.format.glb.pbrMaterial.forceDoubleSidedMaterials",
  "export.0.format.glb.pbrMaterial.forceUnlitMaterials",
  "export.0.format.glb.pbrMaterial.convertMetalRoughness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.baseColor",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.metallicRoughness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.occlusion",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.emissive",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.normal",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.clearcoat",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.clearcoatRoughness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.clearcoatNormal",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.transmission",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.sheenColor",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.sheenRoughness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.specular",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.specularColor",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.thickness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.iridescence",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.iridescenceThickness",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.diffuse",
  "export.0.format.glb.pbrMaterial.maxTextureResolution.specularGlossiness",
  "export.0.format.glb.pbrMaterial.textureFormat.baseColor",
  "export.0.format.glb.pbrMaterial.textureFormat.metallicRoughness",
  "export.0.format.glb.pbrMaterial.textureFormat.occlusion",
  "export.0.format.glb.pbrMaterial.textureFormat.emissive",
  "export.0.format.glb.pbrMaterial.textureFormat.normal",
  "export.0.format.glb.pbrMaterial.textureFormat.clearcoat",
  "export.0.format.glb.pbrMaterial.textureFormat.clearcoatRoughness",
  "export.0.format.glb.pbrMaterial.textureFormat.clearcoatNormal",
  "export.0.format.glb.pbrMaterial.textureFormat.transmission",
  "export.0.format.glb.pbrMaterial.textureFormat.sheenColor",
  "export.0.format.glb.pbrMaterial.textureFormat.sheenRoughness",
  "export.0.format.glb.pbrMaterial.textureFormat.specular",
  "export.0.format.glb.pbrMaterial.textureFormat.specularColor",
  "export.0.format.glb.pbrMaterial.textureFormat.thickness",
  "export.0.format.glb.pbrMaterial.textureFormat.iridescence",
  "export.0.format.glb.pbrMaterial.textureFormat.iridescenceThickness",
  "export.0.format.glb.pbrMaterial.textureFormat.diffuse",
  "export.0.format.glb.pbrMaterial.textureFormat.specularGlossiness",
  "export.0.format.glb.excludeTangents",
  "export.0.format.glb.draco",
  "modifier.screenSize",
];

const evaluateLeaf = (field: Record<string, any>) => {
  let out = false;
  if (field.oneOf) {
    const oneOf: Array<any> = field.oneOf;

    oneOf.forEach((item) => {
      const value: any = _.first(_.values(item.properties));
      const itemType = value.type;
      if (["number", "boolean", "integer"].includes(itemType) || value.enum) {
        out = true;
      }
    });
  }

  return out;
};

const buildFields = (
  parentSection: string,
  properties: Record<string, any>,
  schema: RapidPipelineSchema,
  requiredChildren?: string[],
  hasTabAnchestor?: boolean,
): Field[] => {
  let fields: Field[] = [];

  if (Array.isArray(properties)) {
    properties.forEach((oneOfItem) => {
      const title = oneOfItem.title;

      const entries = Object.entries(oneOfItem.properties).map((entry) => [
        entry[0],
        //@ts-ignore
        { ...entry[1], title },
      ]);
      const properties = Object.fromEntries(entries);

      fields = fields.concat(
        buildFields(
          parentSection,
          properties,
          schema,
          undefined,
          hasTabAnchestor,
        ),
      );
    });

    return fields;
  }

  // eslint-disable-next-line prefer-const
  for (let [key, value] of Object.entries(properties)) {
    const accessPath = parentSection ? `${parentSection}.${key}` : key;

    if (accessPath === "export") {
      const properties = value.items.properties;

      fields.push({
        name: value.title,
        id: value.settingid,
        description: value.description,
        defaultValue: value.default,
        type: "array",
        childFields: buildFields(
          "export.0",
          {
            ...properties,
            format: {
              ...properties.format,
              oneOf: [properties.format.oneOf[1]], //glb export
            },
          },
          schema,
          undefined,
          hasTabAnchestor,
        ),
        minimum: value.minimum,
        maximum: value.maximum,
        accessPath,
        enum: value.enum,
        toggleable: value.toggleable,
        isRequired: requiredChildren?.includes(key),
        key,
      });
      continue;
    }

    if (PROPERTIES_TO_SKEP.includes(accessPath)) {
      continue;
    }

    let type: FieldType = "object";
    let childFields = undefined;

    if (value["$ref"]) {
      const title = value.title;
      value = _.get(schema, value["$ref"].replace("#/", "").replace("/", "."));

      if (title) {
        value.title = title;
      }
    }
    const oneOf = value.oneOf;
    if (oneOf) {
      if (value.settingid === "exportFormat") {
        type = "select";
      } else if (oneOf.length < 3) {
        type = "conditional";
      } else {
        type = "select";
      }
      childFields = buildFields(
        accessPath,
        value.oneOf,
        schema,
        value.required,
        hasTabAnchestor,
      );
    } else if (_.first(_.values(value.properties))?.type === "object") {
      const firstChild = _.first(_.values(value.properties));
      const isLeaf = evaluateLeaf(firstChild);
      type = isLeaf ? "object" : hasTabAnchestor ? "inner-tabs" : "tabs";
      childFields = buildFields(
        accessPath,
        value.properties,
        schema,
        value.required,
        true,
      );
    } else if (value.enum) {
      type = "string-select";
    } else {
      switch (value.type) {
        case "object":
          type = "object";
          childFields = buildFields(
            accessPath,
            value.properties,
            schema,
            value.required,
            hasTabAnchestor,
          );
          break;
        case "boolean":
          type = "checkbox";
          break;
        case "number":
          type = "number";
          break;
        case "integer":
          type = "number";
          break;
        case "array":
          type = "array";
          break;
      }
    }

    fields.push({
      name: value.title,
      id: value.settingid,
      description: value.description,
      defaultValue: value.default,
      type,
      childFields,
      minimum: value.minimum,
      maximum: value.maximum,
      accessPath,
      enum: value.enum,
      toggleable: value.toggleable,
      isRequired: requiredChildren?.includes(key),
      key,
    });
  }

  return fields;
};

const optimizationsClient = new Optimizations(apiClient);

export const getOptimizationDataActor = fromPromise(async () => {
  const schema = await getSchema();
  const parsedSchema = buildFields("", schema.properties, schema);

  const {
    data: { filteredPresets },
  } = await optimizationsClient.getOptimizationsPresets().execute();

  const presetsData = filteredPresets.map(({ config, ...rest }) => ({
    ...rest,
    config: JSON.parse(config),
  }));

  return { schema, parsedSchema, presetsData };
});

export const optimizeActor = fromPromise<
  void,
  { formData: any; rawmodelId: string }
>(async ({ input }) => {
  await optimizationsClient
    .postOptimizationsRawmodelOptimize(input.rawmodelId, {
      config: input.formData,
    })
    .execute();
});

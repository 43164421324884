import { QuestionDialog, useDialogManager } from "@zakeke/zakeke-ui-widgets";
import QuestionDialogWindow from "components/QuestionDialogWindow";

import useOptimizeActor from "../hooks/useOptimizeActor";

const Ctas = () => {
  const { showDialog, closeDialog } = useDialogManager();
  const optimizeActor = useOptimizeActor();

  function handleOnClick() {
    showDialog(
      "confirmOptimization",
      <QuestionDialog
        windowDecorator={QuestionDialogWindow}
        message="All selected settings will be applied to optimize the 3D model. The process will take just a few minutes, after which the asset will be available in the ‘Manage Asset’ tab."
        showCloseButton={false}
        titleHtml={<h4 className="tw-my-0 tw-text-xl">Confirm Optimization</h4>}
        buttons={[
          { label: "Cancel", outline: true, onClick: closeModal },
          { label: "Confirm", outline: false, onClick: confirmOptimization },
        ]}
      >
        test
      </QuestionDialog>,
    );
  }

  function closeModal() {
    closeDialog("confirmOptimization");
  }

  function confirmOptimization() {
    optimizeActor.send({ type: "optimize" });
    closeDialog("confirmOptimization");
  }

  return (
    <section className="tw-py-10 tw-flex tw-justify-between">
      <button
        onClick={() => optimizeActor.send({ type: "reset.preset" })}
        className="tw-text-[#F46200] tw-font-bold tw-bg-transparent tw-border-none hover:tw-cursor-pointer"
      >
        Reset default
      </button>
      <button
        onClick={handleOnClick}
        className="tw-px-4 tw-py-[10px] tw-bg-[#F46200] tw-text-white tw-font-bold tw-border-none tw-rounded-full hover:tw-cursor-pointer"
      >
        Optimize
      </button>
    </section>
  );
};

export default Ctas;

interface NumberSliderProps {
  value: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function NumberSlider({
  value,
  defaultValue,
  min,
  max,
  onChange,
}: NumberSliderProps) {
  console.log("NUMBER SLIDER VALUE", value);
  return (
    <div className="tw-grid tw-items-center tw-grid-cols-[1fr_auto] tw-gap-4">
      <div className="tw-flex tw-flex-col tw-items-center">
        <input
          type="range"
          min={min ?? 0}
          max={max ?? 100}
          value={value}
          defaultValue={defaultValue ?? value}
          onChange={onChange}
          className="tw-w-full tw-h-1 tw-accent-[#DE5900] tw-outline-none tw-border-none tw-ring-0 focus:tw-outline-none focus:tw-ring-0 focus-visible:tw-outline-none"
        />
        <div className="tw-flex tw-justify-between tw-w-full tw-text-sm tw-mt-1">
          <span className="tw-text-xs">0</span>
          <span className="tw-text-xs">100%</span>
        </div>
      </div>
      <input
        type="number"
        min={min ?? 0}
        max={max ?? 100}
        value={value}
        defaultValue={defaultValue ?? value}
        onChange={onChange}
        className="tw-w-32 tw-accent-[#DE5900] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
      />
    </div>
  );
}

import classNames from "classnames";
import { ReactNode } from "react";

import arrowDown from "@/svg/arrow-down-light.svg?plain";

import "./Accordion.scss";

type AccordionProps = {
  title: string;
  children?: ReactNode;
  isOpen: boolean;
  onClick: () => void;
  section?: boolean;
  hide?: boolean;
  className?: string;
  bodyClassName?: string;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  isOpen,
  onClick,
  section = false,
  hide = false,
  className,
  bodyClassName,
}) => {
  return (
    <div
      id="f5f9b52e1daaec"
      className={classNames("accordion", { "is-section": section }, className)}
      data-testid="toggle-panel"
    >
      <div className="header" onClick={onClick}>
        {title}{" "}
        <img
          src={arrowDown}
          alt={title}
          className={classNames("icon", {
            open: isOpen,
          })}
        />
      </div>
      {isOpen && !hide && (
        <div className={classNames("body", bodyClassName)}>{children}</div>
      )}
      {hide && (
        <div className={classNames("body", { closed: !isOpen })}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;

import { BackButton, ZTooltip } from "@zakeke/zakeke-ui-widgets";
import classnames from "classnames";
import { ReactNode } from "react";

import "./PageHeader.scss";

type PageHeaderProps = {
  title: string;
  icon?: ReactNode;
  onBack?: () => void;
  topBar?: ReactNode;
  subtitle?: string | ReactNode; // Could we also accept a react node?
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  titleTooltip?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  icon,
  onBack,
  topBar,
  subtitle,
  children,
  className,
  titleClassName,
  titleTooltip,
}) => {
  return (
    <header
      id="ph66743f3f20a335ff947d57c5"
      className={classnames("page-header", className, {
        "missing-top-bar": !onBack && !topBar,
      })}
    >
      {(onBack || topBar) && (
        <div className="top-bar">
          {onBack && (
            <div className="action-cell">
              <BackButton onClick={onBack}>Back</BackButton>
            </div>
          )}
          {topBar && <div className="content-cell">{topBar}</div>}
        </div>
      )}
      <div className="title-wrapper">
        <div>
          <ZTooltip title={titleTooltip}>
            <h2 className={classnames({ "has-icon": !!icon }, titleClassName)}>
              {title}
              {icon && <span className="icon">{icon}</span>}
            </h2>
          </ZTooltip>
          {subtitle && (
            <p className="subtitle tw-text-sm [&_a]:tw-text-[#0288d1] [&_a]:tw-underline [&_a]:hover:tw-text-black [&_a]:hover:tw-no-underline">
              {subtitle}
            </p>
          )}
        </div>
        <div className="content">{children}</div>
      </div>
    </header>
  );
};

export default PageHeader;

import { useSelector } from "@xstate/react";
import classNames from "classnames";
import Select from "react-select";

import icon3dedit from "@/svg/3dedit.svg?plain";
import iconExport from "@/svg/export.svg?plain";
import iconImport from "@/svg/import.svg?plain";
import king from "@/svg/king.svg?plain";
import iconMeshCulling from "@/svg/mesh-culling.svg?plain";
import iconModifier from "@/svg/modifier.svg?plain";
import iconOptimize from "@/svg/optimize.svg?plain";
import iconSceneGraphFlattening from "@/svg/scene-graph-flattening.svg?plain";

import Ctas from "./components/Ctas";
import { Field } from "./components/Field";
import useOptimizeActor from "./hooks/useOptimizeActor";

const iconMap: Record<string, string> = {
  import: iconImport,
  "3dEdit": icon3dedit,
  meshCulling: iconMeshCulling,
  optimize: iconOptimize,
  outcomeModifier: iconModifier,
  exportArray: iconExport,
  sceneGraphFlattening: iconSceneGraphFlattening,
};

export default function Template() {
  const optimizeActor = useOptimizeActor();

  const rapidPipelineSchema = useSelector(
    optimizeActor,
    (snap) => snap.context.rapidPipelineSchema,
  );
  const parsedRapidPipelineSchema = useSelector(
    optimizeActor,
    (snap) => snap.context.parsedRapidPipelineSchema,
  );
  const activeConfigurationSection = useSelector(
    optimizeActor,
    (snap) => snap.context.activeConfigurationSection,
  );
  const presetsData = useSelector(
    optimizeActor,
    (snap) => snap.context.presetsData,
  );

  const currentPresetId = useSelector(
    optimizeActor,
    (snap) => snap.context.currentPresetId,
  );

  rapidPipelineSchema &&
    console.log("rapidpipeline schema: ", rapidPipelineSchema);
  const formSettings = useSelector(
    optimizeActor,
    (snap) => snap.context.formData,
  );
  console.log("PARSED SCHEMA", parsedRapidPipelineSchema);
  console.log("FORM SETTINGS", formSettings);

  const getCurrentPresetValue = () => {
    if (currentPresetId === null) {
      return {
        label: "---",
        value: "blank",
      };
    } else if (currentPresetId === "custom") {
      return {
        label: "Custom Settings",
        value: "custom",
      };
    }

    const preset = presetsData.find((item) => item.id === currentPresetId);

    return {
      label: preset?.name,
      value: preset?.id,
    };
  };

  return (
    <>
      {rapidPipelineSchema && (
        <div className="tw-z-10 tw-h-full">
          {/* TODO: change this part using zakeke notificationBanner component once it is correctly exported from zakeke library */}
          <div className="tw-bg-[#F0047F1A] tw-rounded-lg tw-grid tw-grid-cols-[auto_auto_1fr] tw-justify-center tw-my-4">
            <p className="tw-bg-[#F0047F] tw-m-0 tw-px-1 tw-py-1 tw-flex tw-items-center tw-rounded-l-lg">
              <img src={king} alt="" className="icon" />
            </p>
            <p className="tw-px-4 tw-py-1">
              <span className="tw-font-bold">Remember</span>: This feature is
              free for the first five optimizations. After that, a charge fee of
              0.60$ will apply to each optimization.
            </p>
            <a
              className="tw-px-4 tw-py-1 tw-text-xs tw-text-[#F0047F] tw-font-bold tw-flex tw-items-center tw-justify-self-end visited:tw-text-[#F0047F]"
              href="https://zakeke.zendesk.com/hc/en-us/articles/18186651154972-3D-Asset-Optimization"
              target="_blank"
            >
              Learn more
            </a>
          </div>
          <div className="tw-flex tw-bg-white tw-gap-16 tw-p-10">
            <div className="tw-w-1/3 tw-flex tw-flex-col">
              <h4 className="tw-my-0">Select Optimization preset</h4>
              <p>
                Start by selecting your preferred settings or choosing a
                pre-configured preset tailored to a specific use case.
              </p>
              <ul className="tw-flex tw-flex-col tw-list-none tw-pl-0">
                {parsedRapidPipelineSchema.map((tab) => {
                  return (
                    <li
                      key={tab.id}
                      className={classNames(
                        "tw-grid tw-grid-cols-[1.5rem_1fr] tw-gap-1 tw-items-center tw-mb-1 tw-p-3 tw-font-bold tw-shadow hover:tw-cursor-pointer",
                        {
                          "tw-text-[#F46200] tw-shadow-orange-100":
                            tab.id === activeConfigurationSection,
                        },
                      )}
                      onClick={() =>
                        optimizeActor.send({
                          type: "newActiveConfigurationSection",
                          payload: tab.id,
                        })
                      }
                    >
                      <img
                        src={iconMap[tab.id]}
                        alt=""
                        className={classNames("icon", {
                          "tw-filter tw-brightness-0 tw-saturate-100 tw-invert-[58%] tw-sepia-[29%] tw-saturate-[7178%] tw-hue-rotate-[352deg] tw-brightness-[101%] tw-contrast-[95%]":
                            tab.id === activeConfigurationSection,
                        })}
                      />
                      {tab.name}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="tw-w-2/3 tw-flex tw-flex-col">
              <Select
                className="tw-mb-6"
                styles={{
                  control: (provided: any) => ({
                    ...provided,
                    fontSize: "0.875rem",
                    border: "1px solid #edeeef",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #edeeef",
                    },
                  }),
                  option: (provided: any, state: { isFocused: boolean }) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? "#f6f8f9" : "white",
                    color: "black",
                  }),
                }}
                value={getCurrentPresetValue()}
                onChange={(selected) => {
                  if (selected?.value === "blank") {
                    optimizeActor.send({ type: "reset.preset" });
                  } else {
                    optimizeActor.send({
                      type: "select.preset",
                      payload: { presetId: Number(selected?.value) },
                    });
                  }
                }}
                options={[
                  {
                    label: "---",
                    value: "blank",
                  },
                  ...presetsData.map((item) => ({
                    label: (
                      <div>
                        <div className="tw-font-bold tw-text-sm">
                          {item.name.toUpperCase()}
                        </div>
                        <div className="tw-mt-1 tw-text-sm">
                          {item.description}
                        </div>
                      </div>
                    ),
                    value: item.id,
                  })),
                ]}
              />

              {parsedRapidPipelineSchema.map((tab) => {
                if (activeConfigurationSection !== tab.id) {
                  return null;
                }
                if (
                  ["outcomeModifier", "sceneGraphFlattening"].includes(tab.id)
                ) {
                  return (
                    <div key={tab.id} className="tw-bg-[#f6f8f9] tw-p-8">
                      <h4 className="tw-my-0">{tab.name}</h4>
                      <div className="tw-bg-white tw-py-2 tw-mt-4">
                        <Field field={tab} />
                      </div>
                    </div>
                  );
                }

                return (
                  <div key={tab.id} className="tw-bg-[#f6f8f9] tw-p-8">
                    <h4 className="tw-my-0">{tab.name}</h4>
                    <p>{tab.description}</p>
                    <Field field={tab} isRoot />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="tw-bg-[#f6f8f9] tw-mt-auto tw-sticky tw-bottom-0 tw-z-20">
            <Ctas />
          </div>
        </div>
      )}
    </>
  );
}

/* eslint-disable prettier/prettier */
import { useMachine } from "@xstate/react";
import SpinnerWrapper from "components/SpinnerWrapper";
import { addToast } from "libs/toaster";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import optimizeMachine from "@/blackbox/machines/optimization/optimize";
import PageHeader from "@/components/PageHeader";
import { OptimizeContext } from "@/contexts/Optimize";
import { MainPageLayout } from "@/layouts";
import Template from "@/templates/Optimize/Template.tsx";

export default function Optimize() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, , optimizeActor] = useMachine(
    optimizeMachine.provide({
      actions: {
        onOptimizeSuccess: () => {
          navigate(`/assets/${id}`, {
            state: { optimizationRequestStatus: "success" },
          });
        },
        onOptimizeFail: (error: unknown) => {
          //@ts-ignore
          addToast(
            `Asset optimization fails: ${error?.message ?? "Unkown error"}`,
            "error",
          );
        },
      },
    }),
    {
      input: {
        assetId: id!,
      },
    },
  );

  const error = state.context.error;

  useEffect(() => {
    if (error) {
      //@ts-ignore
      addToast(
        `Asset optimization fails: ${error?.message ?? "Unkown error"}`,
        "error",
      );
    }
  }, [error]);

  return (
    <OptimizeContext.Provider value={optimizeActor}>
      <MainPageLayout
        header={
          <PageHeader
            title={"3D Asset Optimization"}
            subtitle={
              "Optimization settings automatically reduce the 3D model’s file size, polygon count, vertex count, and texture size, ensuring seamless real-time rendering and augmented reality compatibility, without compromising visual quality."
            }
            className={"tw-pb-2"}
            onBack={() => navigate(`/assets/${id}`)}
          />
        }
      >
        <SpinnerWrapper
          spinning={
            // @ts-ignore
            state.matches("mounting") ||
            // @ts-ignore
            state.matches("changing")
          }
        >
          <Template />
        </SpinnerWrapper>
      </MainPageLayout>
    </OptimizeContext.Provider>
  );
}

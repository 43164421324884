import { useSelector } from "@xstate/react";
import {
  Checkbox,
  Radio,
  Select,
  ZTabs,
  ZTooltip,
} from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { RgbaColorPicker } from "react-colorful";
import { Tab, TabList, TabPanel } from "react-tabs";

import { Field as FieldType } from "@/blackbox/machines/optimization/types";
import GlobalAccordion from "@/components/Accordion";
import NumberSlider from "@/components/NumberSlider";

import useOptimizeActor from "../hooks/useOptimizeActor";

type FieldInfoProps = {
  name: string;
  description: string;
  className?: string;
};

const FieldInfo: React.FC<FieldInfoProps> = ({
  name,
  description,
  className,
}) => (
  <div className={className}>
    <label className="tw-text-base tw-font-bold">{name}</label>
    <p className="tw-mt-3 tw-mb-2">{_.capitalize(description)}</p>
  </div>
);

type TabsProps = {
  field: FieldType;
  isRoot?: boolean;
};

const Tabs: React.FC<TabsProps> = ({ field, isRoot }) => {
  const optimizeActor = useOptimizeActor();
  const formData = useSelector(optimizeActor, (snap) => snap.context.formData);
  const isChecked = !_.isNil(_.get(formData, field.accessPath!));
  const hasCheckbox = field.toggleable && !isRoot;

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {hasCheckbox ? (
        <Checkbox
          className="tw-mx-4"
          label={field.name}
          caption={field.description}
          checked={isChecked}
          onChange={(checked) =>
            optimizeActor.send({
              type: "update.formData",
              payload: {
                accessPath: field.accessPath!,
                value: checked ? {} : undefined,
              },
            })
          }
        />
      ) : (
        !isRoot && (
          <FieldInfo
            className="tw-ml-4"
            name={field.name}
            description={field.description ?? ""}
          />
        )
      )}
      {(!hasCheckbox || (hasCheckbox && isChecked)) && (
        <ZTabs className="tw-mt-2">
          <TabList>
            {field.childFields?.map((field) => (
              <Tab key={field.id}>
                <span className="tw-text-sm">{field.name}</span>
              </Tab>
            ))}
          </TabList>
          {field.childFields?.map((field) => (
            <TabPanel key={field.id}>
              <div className="tw-py-4 tw-bg-white">
                <Field field={field} />
              </div>
            </TabPanel>
          ))}
        </ZTabs>
      )}
    </div>
  );
};

type InnerTabsProps = {
  field: FieldType;
};

const InnerTabs: React.FC<InnerTabsProps> = ({ field }) => {
  const optimizeActor = useOptimizeActor();
  const formData = useSelector(optimizeActor, (snap) => snap.context.formData);
  const isChecked = !_.isNil(_.get(formData, field.accessPath!));
  const hasCheckbox = field.toggleable;

  const [selectedField, setSelectedField] = useState(field.childFields![0]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {hasCheckbox ? (
        <Checkbox
          className="tw-mx-4"
          label={field.name}
          caption={field.description}
          checked={isChecked}
          onChange={(checked) =>
            optimizeActor.send({
              type: "update.formData",
              payload: {
                accessPath: field.accessPath!,
                value: checked ? {} : undefined,
              },
            })
          }
        />
      ) : (
        <FieldInfo
          className="tw-ml-4"
          name={field.name}
          description={field.description ?? ""}
        />
      )}
      {(!hasCheckbox || (hasCheckbox && isChecked)) && (
        <div className="tw-mt-2">
          <div className="tw-flex tw-mx-4 tw-border-solid tw-border-0 tw-border-b tw-border-[#dbe2e6]">
            {field.childFields?.map((field) => (
              <div
                key={field.id}
                onClick={() => setSelectedField(field)}
                className={classNames("tw-p-4 tw-cursor-pointer", {
                  "tw-text-[#445A64]": selectedField.id !== field.id,
                  "tw-border-solid tw-border-0 tw-border-b tw-border-[--primary-color] tw-text-[--primary-color] tw-font-semibold":
                    selectedField.id === field.id,
                })}
              >
                <span className="tw-text-sm">{field.name}</span>
              </div>
            ))}
          </div>
          <div key={field.id} className="tw-p-4 tw-bg-white">
            <Field field={selectedField} />
          </div>
        </div>
      )}
    </div>
  );
};

type AccordionProps = {
  field: FieldType;
};

const Accordion: React.FC<AccordionProps> = ({ field }) => {
  const [selectedAccordion, setSelectedAccordion] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (field.childFields && field.childFields.length > 0) {
      setSelectedAccordion(field.childFields[0].id);
    }
  }, [field.childFields]);

  return field.childFields?.map((field) => {
    return (
      <GlobalAccordion
        className="tw-bg-white tw-mx-4 tw-mt-4"
        key={field.id}
        title={field.name}
        isOpen={selectedAccordion === field.id}
        onClick={() => {
          setSelectedAccordion((prev) =>
            prev === field.id ? undefined : field.id,
          );
        }}
      >
        <Field field={field} />
      </GlobalAccordion>
    );
  });
};

const Conditional = ({ field }: { field: FieldType }) => {
  const optimizeActor = useOptimizeActor();
  const formData = useSelector(optimizeActor, (snap) => snap.context.formData);

  let currentAccessPath = field.childFields![0]!.accessPath!;
  field.childFields?.forEach((childField) => {
    const obj = _.get(formData, childField.accessPath!);

    if (obj) {
      currentAccessPath = childField.accessPath!;
    }
  });

  const currentValue = _.get(formData, field.accessPath!);
  const isChecked = _.isObject(currentValue) || !field.toggleable;

  return (
    <div className="tw-mt-2">
      {
        <div className="tw-flex tw-flex-col tw-gap-4">
          {field.toggleable && (
            <Checkbox
              className="tw-mx-4"
              label={field.name}
              caption={field.description}
              checked={isChecked}
              onChange={(checked) =>
                optimizeActor.send({
                  type: "update.formData",
                  payload: {
                    accessPath: field.accessPath!,
                    value: checked ? {} : undefined,
                  },
                })
              }
            />
          )}
          {!field.toggleable && (
            <FieldInfo
              className="tw-mx-4"
              name={field.name}
              description={field.description ?? ""}
            />
          )}
          <div
            className={classNames(
              "tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-pl-0 tw-mx-4",
              {
                "tw-ml-[46px]": [
                  "modelOptimizationMethod",
                  "materialEdit_materialReplacer",
                  "decimatorVerticesTarget",
                  "decimatorVerticesTarget",
                  "outcomeModifier",
                  "generateUVsDefaultMtl",
                  "sceneGraphFlattening",
                ].includes(field.id),
              },
            )}
          >
            {field.childFields
              ?.filter(
                (childField) =>
                  childField.id !== "materialReplacer_defaultMaterial",
              )
              .map((childField) => {
                return (
                  <Radio
                    name={childField.name}
                    value=""
                    checked={childField.accessPath === currentAccessPath}
                    disabled={!isChecked}
                    key={childField.name}
                    label={childField.name}
                    onSelect={() => {
                      const key = childField.accessPath?.split(".").pop();
                      optimizeActor.send({
                        type: "update.formData",
                        payload: {
                          accessPath: field.accessPath!,
                          value: {
                            [key as string]: field.defaultValue ?? {},
                          },
                        },
                      });
                    }}
                  />
                );
              })}
          </div>
        </div>
      }

      <div className="tw-py-3 tw-flex tw-flex-col tw-gap-2">
        {field.childFields &&
          isChecked &&
          field.childFields
            ?.filter(
              (childField) => childField.accessPath === currentAccessPath,
            )
            .map((childField) => (
              <Field key={childField.id} field={childField} />
            ))}
      </div>
    </div>
  );
};

type SelectProps = {
  field: FieldType;
};

const FieldSelect: React.FC<SelectProps> = ({ field }) => {
  const optimizeActor = useOptimizeActor();
  const selectedValue = useSelector(optimizeActor, (snap) =>
    _.get(snap.context.formData, field.accessPath!),
  );
  const key = selectedValue ? Object.keys(selectedValue) : null;
  const currentPath = key
    ? `${field.accessPath}.${key}`
    : field.childFields![0].accessPath;

  return (
    <div className="tw-mt-2 tw-mx-4">
      <FieldInfo name={field.name} description={field.description ?? ""} />
      <Select
        disabled={field.id === "exportFormat"}
        className="tw-max-w-lg"
        value={currentPath}
        onChange={(value) => {
          const key = value.split(".").pop();
          optimizeActor.send({
            type: "update.formData",
            payload: {
              accessPath: field.accessPath!,
              value: {
                [key as string]: {},
              },
            },
          });
        }}
      >
        {field.childFields!.map((item: FieldType) => (
          <option key={item.id} value={item.accessPath}>
            {item.name}
          </option>
        ))}
      </Select>

      <div className="tw-py-3 tw-flex tw-flex-col tw-gap-2">
        {field.childFields && (
          <Field
            field={
              field.childFields.find(
                (childField) => childField.accessPath === currentPath,
              )!
            }
          />
        )}
      </div>
    </div>
  );
};

type StringSelectProps = {
  field: FieldType;
};

const StringSelect: React.FC<StringSelectProps> = ({ field }) => {
  const optimizeActor = useOptimizeActor();
  const selectedValue = useSelector(optimizeActor, (snap) =>
    _.get(snap.context.formData, field.accessPath!),
  );

  return (
    <div
      className={classNames("tw-mt-2 tw-mx-4", {
        "tw-ml-0": ["flattening:mode"].includes(field.id),
        "tw-p-4": ["flattening:mode"].includes(field.id),
      })}
    >
      <FieldInfo name={field.name} description={field.description ?? ""} />
      <Select
        className="tw-max-w-lg"
        value={selectedValue ?? field.defaultValue}
        onChange={(value) => {
          optimizeActor.send({
            type: "update.formData",
            payload: {
              accessPath: field.accessPath!,
              value,
            },
          });
        }}
      >
        {field.enum?.map((value) => <option key={value}>{value}</option>)}
      </Select>
    </div>
  );
};

type FieldProps = {
  field: FieldType;
  isRoot?: boolean;
  tabsNestingIndex?: number;
};

const getMinimum = (value: number | undefined) => {
  if (!value) {
    return value;
  }

  if (value === 0 || value >= 1) {
    return value;
  }

  return 1;
};

export const Field: React.FC<FieldProps> = ({ field, isRoot }) => {
  const optimizeActor = useOptimizeActor();
  const formData = useSelector(optimizeActor, (snap) => snap.context.formData);

  let fieldValue;
  if (field.accessPath) {
    fieldValue = _.get(formData, field.accessPath);
  }

  const numberSliderList = [
    "smallFeatureSizeCullingPercentage",
    "decimatorFacesPercentage",
    "decimatorVerticesPercentage",
  ];

  if (field.type === "tabs" && field.childFields) {
    return <Tabs field={field} isRoot={isRoot} />;
  }

  if (field.type === "inner-tabs") {
    return <InnerTabs field={field} />;
  }

  if (field.type === "accordion" && field.childFields) {
    return <Accordion field={field} />;
  }

  if (field.type === "conditional") {
    return <Conditional field={field} />;
  }

  if (field.type === "select") {
    return <FieldSelect field={field} />;
  }

  if (field.type === "string-select") {
    return <StringSelect field={field} />;
  }

  const selectedColor = _.get(
    formData,
    `["3dEdit"].materialEdit.materialReplacer.defaultMaterial.baseColor`,
  );

  const isFieldToggled = !_.isNil(_.get(formData, field.accessPath!));

  if (field.toggleable && !isFieldToggled) {
    return (
      <Checkbox
        className="tw-mx-4"
        label={field.name}
        caption={field.description}
        checked={false}
        onChange={(checked) =>
          optimizeActor.send({
            type: "update.formData",
            payload: {
              accessPath: field.accessPath!,
              value: checked ? field.defaultValue || {} : undefined,
            },
          })
        }
      />
    );
  }

  return (
    <>
      {field.toggleable && (
        <Checkbox
          className="tw-mx-4 test"
          label={field.name}
          caption={field.description}
          checked={true}
          //@ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onChange={(checked) =>
            optimizeActor.send({
              type: "update.formData",
              payload: {
                accessPath: field.accessPath!,
                value: undefined,
              },
            })
          }
        />
      )}
      {(field.id === "modifierSizeOnDisk" ||
        field.id === "generateUVsDefaultMtl") &&
        null}
      {field.type === "checkbox" && (
        <div className="tw-flex tw-flex-row tw-gap-1 tw-mx-4 tw-mt-2">
          <Checkbox
            label={<ZTooltip title={field.description}>{field.name}</ZTooltip>}
            checked={fieldValue ?? field.defaultValue}
            onChange={(checked) => {
              optimizeActor.send({
                type: "update.formData",
                payload: {
                  accessPath: field.accessPath!,
                  value: checked,
                },
              });
            }}
          />
        </div>
      )}
      {field.type === "number" && numberSliderList.includes(field.id) && (
        <div className="tw-px-4">
          <NumberSlider
            value={
              _.isObject(fieldValue)
                ? Number(field.defaultValue)
                : fieldValue ?? Number(field.defaultValue)
            }
            defaultValue={Number(field.defaultValue)}
            // min={field.minimum && field.minimum < 1 ? 1 : field.minimum}
            min={getMinimum(field.minimum)}
            max={field.maximum}
            onChange={(event) => {
              optimizeActor.send({
                type: "update.formData",
                payload: {
                  accessPath: field.accessPath!,
                  value: Number(event.target.value),
                },
              });
            }}
          />
        </div>
      )}
      {field.type === "number" && !numberSliderList.includes(field.id) && (
        <div
          className={classNames("tw-flex tw-flex-col tw-gap-1 tw-mx-4", {
            "tw-ml-0 tw-mt-2 tw-p-4": [
              "flattening:preservedSceneDepth",
            ].includes(field.id),
          })}
        >
          <FieldInfo name={field.name} description={field.description ?? ""} />
          <input
            type="number"
            id={field.id.replace(":", "")}
            name={field.id.replace(":", "")}
            defaultValue={Number(field.defaultValue)}
            value={
              _.isObject(fieldValue)
                ? Number(field.defaultValue)
                : fieldValue ?? Number(field.defaultValue)
            }
            min={getMinimum(field.minimum)}
            max={field.maximum}
            className="tw-w-32 tw-accent-[#F46200] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
            onChange={(event) => {
              optimizeActor.send({
                type: "update.formData",
                payload: {
                  accessPath: field.accessPath!,
                  value: Number(event.target.value),
                },
              });
            }}
          />
        </div>
      )}
      {field.id === "material:defaultBaseColor" && (
        <div className="tw-mx-4">
          <FieldInfo name={field.name} description={field.description ?? ""} />
          <RgbaColorPicker
            color={
              selectedColor
                ? {
                    r: selectedColor[0] * 255,
                    g: selectedColor[1] * 255,
                    b: selectedColor[2] * 255,
                    a: selectedColor[3],
                  }
                : { r: 255, g: 255, b: 255, a: 1 }
            }
            onChange={(color) => {
              const r = color.r / 255;
              const g = color.g / 255;
              const b = color.b / 255;

              optimizeActor.send({
                type: "update.formData",
                payload: {
                  value: [r, g, b, color.a],
                  accessPath: field.accessPath!,
                },
              });
            }}
          />
        </div>
      )}
      {field.childFields &&
        field.childFields.map((field) => (
          <Field key={field.id} field={field} />
        ))}
    </>
  );
};
